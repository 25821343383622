import request from './http'

// 代理商登录接口
export const login = (params) => request('/login', 'post', params)
// 用户列表查询接口
export const getUser = (params) => request('/get_user', 'get', params)
// 添加用户接口
export const addUser = (params) => request('/add_user', 'post', params)
// 编辑用户接口
export const editUser = (params) => request('/edit_user', 'post', params)
// 用户充值接口
export const userOrder = (params) => request('/user_order', 'post', params)
// 订单查询接口
export const getOrder = (params) => request('/get_order', 'get', params)
// 套餐查询接口
export const getSetmeal = (params) => request('/get_setmeal', 'get', params)