import { Modal, Table, Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { getSetmeal } from '../../api'

const PrdDialog = ({ visible, setVisible, onOk, type }) => { // type弹框类型 add:添加 recharge:充值
  const [active, setActive] = useState({})
  const [value, setValue] = useState('')
  const [data, setData] = useState([])
  const columns = [
    // {
    //   title: '套餐id',
    //   dataIndex: 'id',
    //   key: 'id',
    //   align: 'center',
    // },
    {
      title: '套餐名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: '原价(元)',
      dataIndex: 'sale_price',
      key: 'sale_price',
      align: 'center',
      render: text => (text/100).toFixed(2)
    },
    {
      title: '折扣',
      dataIndex: 'discount_desc',
      key: 'discount_desc',
      align: 'center'
    },
    {
      title: '实付价(元)',
      dataIndex: 'discount_price',
      key: 'discount_price',
      align: 'center',
      render: text => (text/100).toFixed(2)
    },
  ];

  const getData = async () => {
    const result = await getSetmeal()
    setData(result.items)
  }

  useEffect(() => {
    if(visible) getData() // 要求弹窗出现就要重新调套餐接口
  }, [visible])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setActive(selectedRows[0])
    }
  };

  const clickOk = () => {
    console.log(active)
    if (!active.id) return
    onOk({ ...active, remark: value})
  }

  return (
    <div>
      <Modal
        title="充值套餐" 
        cancelText={'取消'} 
        okText={'确定'} 
        width={800} 
        visible={visible} 
        onOk={clickOk} 
        onCancel={() => setVisible(false)}
        footer={[
          type === 'add' ? <Form.Item
            name={`remark`}
            label={`备注`}
            getValueFromEvent={e => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
          >
            <Input value={value} onChange={e => setValue(e.target.value)} />
          </Form.Item> : null
        ,
          <Button onClick={() => setVisible(false)}>
            取消
          </Button>,
          <Button
            type="primary"
            onClick={clickOk}
          >
            确定
          </Button>
        ]}
      >
        <Table 
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          columns={columns} 
          rowKey={record => record.id}
          dataSource={data} 
          pagination={false} />
      </Modal>
    </div>
  )
}

export default PrdDialog