import { HashRouter, Switch, Route } from 'react-router-dom'
import routes from './config'

const handleRoute = (routes) => {
  return (
    <Switch>
      {
        routes.map(item => {
          return (
            <Route 
              path={item.path} 
              key={item.path} 
              render={() => {
                return (
                  <item.component>
                    {
                      item.children && handleRoute(item.children)
                    }
                  </item.component>
                )
              }}
            />
          )
        })
      }
    </Switch>
  )
}

const Router = () => {
  return (
    <HashRouter>
      {
        handleRoute(routes)
      }
    </HashRouter>
  )
}

export default Router