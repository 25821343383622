//解析url上参数的值
export function getQueryVal(url, variable) {
  let query = url.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] === variable) {
      return decodeURI(pair[1]);
    }
  }
  return false;
}