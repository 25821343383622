import React, { useState } from 'react';
import { Form, Row, Col, Input, Button, DatePicker } from 'antd';
import moment from 'moment'

const { RangePicker } = DatePicker;

const FiltrateComp = ({onSearch, defaultValue}) => {
  const [form] = Form.useForm();
  const [start, setStart] = useState()
  const [end, setEnd] = useState()

  const { digital_id } = defaultValue

  const onFinish = (values) => {
    console.log(values)
    onSearch({
      ...values,
      start,
      end
    })
  };
  const onChange = (values) => {
    if (values) {
      const [startMoment, endMoment] = values
      setStart(startMoment.format('YYYY-MM-DD HH:mm:ss'))
      setEnd(endMoment.format('YYYY-MM-DD HH:mm:ss'))
    } else {
      setStart('')
      setEnd('')
    }
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col>
          <span>创建时间：</span>
          <RangePicker value={[start ? moment(start) : null, end ? moment(end) : null]} showTime={{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')] }} onChange={onChange} />
        </Col>
        <Col span={6}>
          <Form.Item
            name={`digital_id`}
            label={`数字账号`}
            getValueFromEvent={e => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
            initialValue={digital_id || ''}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={`email`}
            label={`邮箱`}
            getValueFromEvent={e => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => {
              form.resetFields();
              setStart(null)
              setEnd(null)
              form.setFieldsValue({ // digital_id有默认值时resetFields无法清除
                digital_id: ''})
            }}
          >
            清空
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FiltrateComp