import { useContext, useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import $styles from './index.module.scss'
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { context } from '../../store'

const { Header, Sider, Content } = Layout;

const Main = ({children}) => {
  const {state} = useContext(context)
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)

  const viewConf = require('../../router/config').default.find(item => item.path === '/').children

  const toggle = () => {
    setCollapsed(!collapsed)
  };

  if (!sessionStorage.getItem('token')) return <Redirect to='login' />
  if (history.location.pathname === '/') return <Redirect from='/' to='recharge' />

  const loginout = () => {
    sessionStorage.removeItem('token')
    history.push('/login')
  }

  // 获取商户余额
  const getBalance = () => {
    const val = state.balance || sessionStorage.getItem('balance')
    return (val/100).toFixed(2)
  }

  return (
    <Layout>
      <Sider className={$styles.mainSider} trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" style={{height: '100px'}} />
        <Menu theme="dark" mode="inline" selectedKeys={[history.location.pathname]}>
          {
            viewConf.map(item => (
              <Menu.Item key={item.path} icon={item.icon} onClick={() => history.push(item.path)}>
                {item.title}
              </Menu.Item>
            ))
          }
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className={$styles.mainHeader}>
          <span onClick={toggle} style={{fontSize: '20px'}}>
            {
              collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
            }
          </span>
          <span className={$styles.balanceBox}>余额: ¥{getBalance()}</span>
          <Button type="link" onClick={loginout}>退出登陆</Button>
        </Header>
        <Content
          className={$styles.mainContent}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Main