import { createContext, useReducer } from "react"

const initState = {
  balance: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_balance':
      sessionStorage.setItem('balance', action.balance) // 页面刷新store会消失,所以每次更新都在sesssessionStorage存一下
      return {...state, balance: action.balance}
    default:
      return state
  }
}

export const context = createContext()

const ContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <context.Provider value={{state, dispatch}}>
      {children}
    </context.Provider>
  )
}

export default ContextProvider