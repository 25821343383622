import React, { useContext, useEffect, useState } from 'react';
import { Table, Input, InputNumber, Form, Button, message } from 'antd';
import { getUser, editUser, addUser, userOrder } from '../../api'
import PrdDialog from '../../components/prdDialog'
import RechargeFiltro from '../../components/rechargeFiltro'
import { context } from '../../store'
import { useHistory } from 'react-router-dom'

const initReqParams = {
  page: 1,
  page_size: 10,
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          getValueFromEvent={e => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: dataIndex === 'password' ? true : false,
              message: `请输入${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Recharge = () => {
  const { dispatch } = useContext(context)
  const history = useHistory()
  const [form] = Form.useForm();
  const [data, setData] = useState([]); // 用户列表
  const [reqParams, setReqParams] = useState(initReqParams); // 接口入参
  const [visible, setVisible] = useState(false) // 展示套餐弹框
  const [activeUser, setActiveUser] = useState({}) // 当前要编辑、充值的用户
  const [total, setTotal] = useState(0) // 总数
  const [editingKey, setEditingKey] = useState(''); // 当前编辑用户的key
  const [type, setType] = useState('add'); // 弹框类型 add:添加 recharge:充值

  const isEditing = (record) => record.id === editingKey;
  // 点击编辑
  const edit = (record) => {
    setActiveUser(record)
    form.setFieldsValue({
      id: '',
      email: '',
      password: '',
      created_at: '',
      setmeal_expired_at: '',
      ...record,
    });
    setEditingKey(record.id);
  };
  // 取消编辑
  const cancel = () => {
    setEditingKey('');
  };
  // 保存编辑
  const save = async () => {
    const row = await form.validateFields();
    if (!row.password) return
    const params = {
      password: row.password, // 密码
      digital_id: activeUser.digital_id,  // 数字账号
      email: row.email || '', // 邮箱
      remark: row.remark || '', // 备注
    }
    await editUser(params)
    message.success('编辑用户成功');
    getUserList() // 刷新用户列表
  };
  // 点击添加用户按钮
  const clickAddBtn = () => {
    setType('add') // 设置套餐弹框类型
    setVisible(true)
  }
  // 点击充值按钮
  const toRecharge = (record) => {
    console.log(record)
    setType('recharge') // 设置套餐弹框类型
    setVisible(true) // 展示套餐弹框
    setActiveUser(record) // 保存当前用户信息
  }
  // 点击查询充值记录按钮
  const toRecord = (record) => {
    console.log(record)
    history.push(`/record?digital_id=${record.digital_id}`)
  }
  // 充值接口
  const callRecharge = async (data) => {
    const params = {
      digital_id: activeUser.digital_id, // 数字账号
      setmeal_id: data.id, // 套餐id
      discount_price: data.discount_price, // 套餐价格
    }
    const result = await userOrder(params)
    message.success('用户充值成功');
    dispatch({type: 'update_balance', balance: result.balance}) // 设置商户账户余额
    setVisible(false)
    getUserList() // 刷新用户列表
  }

  const columns = [
    {
      title: '数字账号',
      dataIndex: 'digital_id',
      width: '10%',
      editable: false,
      align: 'center'
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      width: '15%',
      editable: true,
      align: 'center'
    },
    {
      title: '密码',
      dataIndex: 'password',
      width: '15%',
      editable: true,
      align: 'center'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: '15%',
      editable: true,
      align: 'center'
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      width: '20%',
      editable: false,
      align: 'center'
    },
    {
      title: '套餐到期时间',
      dataIndex: 'setmeal_expired_at',
      width: '20%',
      editable: false,
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="link" onClick={() => save(record.id, record)}>保存</Button>
            <Button type="link" onClick={cancel}>取消</Button>
          </span>
        ) : (
          <span>
            <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>编辑</Button>
            <Button type="link" disabled={editingKey !== ''} onClick={() => toRecharge(record)}>充值</Button>
            <Button type="link" disabled={editingKey !== ''} onClick={() => toRecord(record)}>查询充值记录</Button>
          </span>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // 获取用户列表
  const getUserList = async () => {
    cancel() // 取消编辑状态
    const params = {
      page: reqParams.page,
      page_size: reqParams.page_size,
      email: reqParams.email || null, // 为空则不能传该字段
      remark: reqParams.remark || null, // 备注
      digital_id: reqParams.digital_id || null, // 数字账号
      start_created_at: reqParams.start || null,
      end_created_at: reqParams.end || null,
    }
    const result = await getUser(params)
    setTotal(result.count)
    setData(result.items)
  }

  // 点击套餐弹框确定按钮
  const onOk = (values) => {
    if (type === 'add') addFn(values) // 确定添加用户
    else callRecharge(values) // 确定充值
  }
  // 添加用户
  const addFn = async (data) => {
    const params = {
      setmeal_id: data.id,  // 套餐id
      discount_price: data.discount_price,  // 实付价
    }
    if (data.remark) params.remark = data.remark // 备注  为空则不能传字段，会报错
    const result = await addUser(params)
    message.success('添加用户成功');
    dispatch({type: 'update_balance', balance: result.balance}) // 设置商户账户余额
    setVisible(false)
    setReqParams({
      ...reqParams,
      ...initReqParams
    })
  }

  useEffect(() => {
    getUserList()
  }, [reqParams])

  // 切换页码
  const handleTableChange = (data) => {
    setReqParams({
      ...reqParams,
      page: data.current,
      page_size: data.pageSize
    })
  }
  // 点击搜索按钮
  const onSearch = (values) => {
    setReqParams({
      ...initReqParams,
      ...values
    })
  }
  return (
    <div>
      <h1>用户列表</h1>
      <Button type="primary" onClick={clickAddBtn} style={{marginBottom: '10px'}}>+添加用户</Button>
      <RechargeFiltro onSearch={onSearch} />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data}
          columns={mergedColumns}
          rowKey={record => record.id}
          pagination={{
            current: reqParams.page,
            pageSize: reqParams.page_size,
            total: total
          }} 
          onChange={handleTableChange}
        />
      </Form>
      <PrdDialog setVisible={setVisible} type={type} visible={visible} onOk={onOk} />
    </div>
  );
};

export default Recharge