import axios from 'axios'
import { message } from 'antd'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL + '/api/v1/agent',
  timeout: 25000,
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (sessionStorage.getItem('token')) config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if (response.data.code === 200) return response.data.data
  message.error(response.data.message);
  if (response.data.code === 403) { // 403	登录态失效
    sessionStorage.removeItem('token')
  }
  return Promise.reject(response.data);
}, function (error) {
  // 对响应错误做点什么
  message.error(error.response.statusText);
  return Promise.reject(error);
});

export default function request (url, method, params) {
  if (process.env.NODE_ENV === 'development' && require('./mock').isMock) { // 本地调试死数据,禁止上生产
    return require('./mock').req(url).data
  }
  return instance[method](url, method === 'get' ? {params} : params)
}