import Record from "../views/record";
import Recharge from "../views/recharge";
import Login from "../views/login";
import Main from "../views/main";

import {
  RedEnvelopeOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/recharge',
        component: Recharge ,
        title: '用户管理',
        icon: <RedEnvelopeOutlined />
      },
      {
        path: '/record',
        component: Record,
        title: '充值记录',
        icon: <UnorderedListOutlined />
      },
    ]
  }
]

export default routes