

import { Table, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getOrder } from '../../api'
import RecordFiltro from '../../components/recordFiltro'
import { getQueryVal } from '../../utils'

const columns = [
  // {
  //   title: '订单id',
  //   dataIndex: 'id',
  //   key: 'id',
  //   align: 'center'
  // },
  {
    title: '订单号',
    dataIndex: 'listid',
    key: 'listid',
    align: 'center'
  },
  {
    title: '数字账号',
    dataIndex: 'digital_id',
    key: 'digital_id',
    align: 'center'
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    align: 'center'
  },
  // {
  //   title: '套餐id',
  //   dataIndex: 'setmeal_id',
  //   key: 'setmeal_id',
  //   align: 'center'
  // },
  {
    title: '套餐名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: '原价(元)',
    dataIndex: 'sale_price',
    key: 'sale_price',
    align: 'center',
    render: text => (text/100).toFixed(2)
  },
  {
    title: '折扣',
    dataIndex: 'discount_desc',
    key: 'discount_desc',
    align: 'center'
  },
  {
    title: '实付价(元)',
    dataIndex: 'discount_price',
    key: 'discount_price',
    align: 'center',
    render: text => (text/100).toFixed(2)
  },
  {
    title: '状态', // 1-新建 2-成功 3-失败
    dataIndex: 'state',
    key: 'state',
    align: 'center',
    render: text => text === 1 ? '新建' : text === 2 ? '成功' : text === 3 ? '失败' : text
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
];

const initReqParams = {
  page: 1,
  page_size: 10,
}

const Record = () => {
  const history = useHistory()
  const digital_id = getQueryVal(history.location.search, 'digital_id') // 用户管理查询携带digital_id进来
  const [total, setTotal] = useState(0) // 总数
  const [orders, setOrders] = useState([])
  const [reqParams, setReqParams] = useState({ ...initReqParams, digital_id}); // 接口入参
  const [sumDiscountPrice, setSumDiscountPrice] = useState('') // 实付金额总计
  const [sumSalePrice, setSumSalePrice] = useState('') // 原价总计


  // 切换页码
  const handleTableChange = (data) => {
    setReqParams({
      ...reqParams,
      page: data.current,
      page_size: data.pageSize
    })
  }

  useEffect(() => {
    console.log(reqParams)
    getOrderList()
  }, [reqParams])

  const getOrderList = async (obj) => {
    const params = {
      page: reqParams.page,
      page_size: reqParams.page_size,
      email: reqParams.email || null, // 为空则不能传该字段
      digital_id: obj ? obj.digital_id : (reqParams.digital_id || null), // 为空则不能传该字段
      start_created_at: reqParams.start || null,
      end_created_at: reqParams.end || null,
    }
    const result = await getOrder(params)
    setOrders(result.items)
    setTotal(result.count)
    setSumDiscountPrice(result.sum_discount_price) // 实付金额总计
    setSumSalePrice(result.sum_sale_price) // 原价总计
  }
  // 点击搜索按钮
  const onSearch = (values) => {
    setReqParams({
      ...initReqParams,
      ...values
    })
  }
  const handlePrice = (val) => {
    if (!val) return '0.00元'
    return (val/100).toFixed(2) + '元'
  }
  return (
    <div>
      <h1>充值记录</h1>
      <RecordFiltro onSearch={onSearch} defaultValue={{ digital_id }} />
      <Table 
        columns={columns} 
        dataSource={orders} 
        pagination={{
          current: reqParams.page,
          pageSize: reqParams.page_size,
          total: total
        }} 
        onChange={handleTableChange} 
        rowKey={record => record.id} />
      <Row>
        <Col style={{marginRight: '30px'}}>
          <span>总笔数：</span>
          <span>{total}</span>
        </Col>
        <Col style={{ marginRight: '30px' }}>
          <span>原价总计：</span>
          <span>{handlePrice(sumSalePrice)}</span>
        </Col>
        <Col>
          <span>实付金额总计：</span>
          <span>{handlePrice(sumDiscountPrice)}</span>
        </Col>
      </Row>
    </div>
  )
}
export default Record