import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import $styles from './index.module.scss'
import { login } from '../../api'
import md5 from 'js-md5'
import { useHistory } from 'react-router';
import { context } from '../../store'
import { useContext } from 'react';

const Login = () => {
  const {dispatch} = useContext(context)
  const history = useHistory()
  const onFinish = async (values) => {
    const params = {
      account: values.account,
      password: md5(values.password)
    }
    const result = await login(params)
    sessionStorage.setItem('token', result.token)
    dispatch({type: 'update_balance', balance: result.balance}) // 设置商户账户余额
    history.push('/')
  };

  return (
    <div className={$styles.loginView}>
      <Form
        name="normal_login"
        className={$styles.loginForm}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <h2 className={$styles.title}>代理商后台</h2>
        <Form.Item
          name="account"
          rules={[{ required: true, message: '请输入用户名' }]}
          getValueFromEvent={e => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
        >
          <Input className={$styles.inputBox} prefix={<UserOutlined />} placeholder="用户名" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: '请输入密码' }]}
          getValueFromEvent={e => e.target.value.replace(/(^\s*)|(\s*$)/g, '')}
        >
          <Input.Password
            className={$styles.inputBox}
            prefix={<LockOutlined />}
            iconRender={(visible) => (visible ? <EyeOutlined style={{color: '#fff'}} /> : <EyeInvisibleOutlined style={{color: '#fff'}} />)}
            type="password"
            placeholder="密码"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={$styles.loginFormBtn}>
            登陆
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login